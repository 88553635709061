

















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private mounted() {
    if (this.$route.query.data) {
      this.data = JSON.parse(this.$route.query.data as any);
    }
  }
}
